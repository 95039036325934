(function ($) {
  Drupal.behaviors.categoryLinksBlockFormatterV1 = {
    attach: function (context) {
      var $content = $('.js-category-links-block-formatter__content', context);
      var $links = $content.find('a.custom-links-block__link');

      // Only do this if we are using the correct content template
      if (!$links) {
        return;
      }
      $links.hover(function () {
        var $this = $(this);

        $links.addClass('disabled');
        $this.removeClass('disabled').addClass('active');
      }, function () {
        $links.removeClass('disabled active');
      });
    }
  };
})(jQuery);
